import request from '@/network/request';


/**
 * ��ѯְҵ����
 */
export async function GetCategories() {
    return request.get('/Category/GetCategories');
}


/**
 * ��ѯְҵ����
 */
export async function GetCategoryById(data) {
    return request.get('/Category/GetCategoryById', data);
}

/**
 * ����ְҵ����
 * @param {any} data
 * 
{
  "name": "string",
  "isDisable": true,
  "parentId": 0
}
 */
export async function addCategory(data) {
    return request.post('/Category/Add', data);
}


/**
 * �༭ְҵ����
 * @param {any} data
 {
  "id": 0,
  "name": "string",
  "isDisable": true,
  "parentId": 0
}
 */
export async function editCategory(data) {
    return request.post('/Category/Edit', data);
}



/* ְҵ��� Start ----------------------------------------- */

/**
 * ְҵ����б�
 */
export async function GetTypes() {
    return request.get('/Type/Get');
}

/**
 * ְҵ�����
 */
export async function GetTypeById(data) {
    return request.get('/Type/GetById', data);
}

/**
 * ְҵ����ҳ
 {
  "isDisable": true,
  "name": "string",
  "pageSize": 0,
  "pageIndex": 0
}
 */
export async function GetTypesByPage(data) {
    return request.post('/Type/GetByPage', data);
}

/**
 * ����ְҵ���
 * @param {any} data
 * 
{
  "name": "string",
  "isDisable": true,
  "des": "string"
}
 */
export async function addType(data) {
    return request.post('/Type/Add', data);
}


/**
 * �༭ְҵ���
 * @param {any} data
{
  "id": 0,
  "name": "string",
  "isDisable": true,
  "des": "string"
}
 */
export async function editType(data) {
    return request.post('/Type/Edit', data);
}


/* ְҵ��� End ----------------------------------------- */




/* ְҵ Start ----------------------------------------- */
/**
 * ְҵ�б�
 */
export async function getPositionsByPage(data) {
    return request.post('/Position/GetByPage', data);
}

export async function getPosition(data) {
    return request.get('/Position/GetById', data);
}


/**
 * �༭ְҵ���
 * @param {any} data
{
  "code": "string",
  "image": "string",
  "name": "string",
  "isDisable": true,
  "wages": 0,
  "wagesUnit": 0,
  "salary": "string",
  "longitude": "string",
  "latitude": "string",
  "address": "string",
  "salaryDesc": "string",
  "desc": "string",
  "requirements": "string",
  "companyIntroduction": "string",
  "categoryId": 0,
  "typeId": 0,
  "carousels": [
    {
      "fileName": "string",
      "filePath": "string"
    }
  ]
}
 */
export async function addPosition(data) {
    return request.post('/Position/Add', data);
}



export async function editPosition(data) {
    return request.post('/Position/Edit', data);
}

/* ְҵ End ----------------------------------------- */