import request from '@/network/request';


/**
 * ȫ��
 */
export async function getCities() {
    return request.get('/City/GetCities');
}


/**
 * ����
 * @param {any} data
 */
export async function addCity(data) {
    console.log('[addCity]' + JSON.stringify(data));
    return request.post('/City/Add', data);
}


/**
 * �༭
 * @param {any} data
 */
export async function editCity(data) {
    //console.log('[editCity]' + JSON.stringify(data));
    return request.post('/City/EditCity', data);
}