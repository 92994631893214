const data = {
    //提现状态
    cashStated: [{
        value: -1,
        name: '全部'
    },
    {
        value: 0,
        name: '待审核'
    },
    {
        value: 1,
        name: '已通过'
    },
    {
        value: 2,
        name: '已驳回'
    }],

    cashItype: [{
        value: -1,
        name: '全部'
    },
    {
        value: 0,
        name: '银行卡提现'
    },
    {
        value: 1,
        name: '支付宝提现'
    }],
    //代理申请状态
    agentApplyStated: [{
        value: -1,
        name: '全部'
    },
    {
        value: 0,
        name: '待审核'
    },
    {
        value: 1,
        name: '已通过'
    },
    {
        value: 2,
        name: '已驳回'
    }],
    userItype: [{
        value: -1,
        name: '全部'
    },
    {
        value: 0,
        name: '普通用户'
    },
    {
        value: 1,
        name: '营销总监'
    },
    {
        value: 2,
        name: '营业部'
    }],
    //禁用
    isDisable: [{
        value: null,
        name: '全部'
    },
    {
        value: false,
        name: '启用'
    },
    {
        value: true,
        name: '禁用'
    }],
    //状态
    verifyStated: [{
        value: 0,
        name: '驳回'
    },
    {
        value: 1,
        name: '通过'
    }],
    //结算方式
    salaryUnit: [{
        value: 0,
        name: '其他'
    },
    {
        value: 1,
        name: '时结'
    },
    {
        value: 2,
        name: '天结'
    },
    {
        value: 3,
        name: '周结'
    },
    {
        value: 4,
        name: '月结'
    },
    {
        value: 5,
        name: '季结'
    },
    {
        value: 6,
        name: '年结'
    }],
    //性别限制
    sexLimits: [{
        value: 0,
        name: '不限'
    },
    {
        value: 1,
        name: '男'
    },
    {
        value: 2,
        name: '女'
    }],
    //申请状态
    applyStated: [{
        value: -100,
        name: '全部'
    },
    {
        value: 0,
        name: '报名成功'
    },
    {
        value: 1,
        name: '取消报名'
    },
    {
        value: 2,
        name: '面试邀请'
    },
    {
        value: 3,
        name: '确认录取'
    },
    {
        value: 4,
        name: '取消录取'
    }],
    //学历要求
    educationList: [{
        id: 0,
        name: '不限'
    },
    {
        id: 1,
        name: '高中/职高/技校及以上'
    },
    {
        id: 2,
        name: '专科及以上'
    },
    {
        id: 3,
        name: '本科及以上'
    },
    {
        id: 4,
        name: '硕士及以上'
    },
    {
        id: 5,
        name: '博士及以上'
    }],

}

module.exports = Object.assign({}, data)