<template>
    <el-dialog :modal-append-to-body='false'
               :title="title"
               :visible.sync="dialogFormVisible"
               width=500px
               @close="close">

        <div class="app-container">
            <el-form ref="form" :model="mapLocation" label-width="60px">
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24">
                        <el-form-item label="查询:">
                            <el-autocomplete v-model="keyword"
                                             :fetch-suggestions="querySearch"
                                             placeholder="请输入详细地址"
                                             style="width: 100%"
                                             :trigger-on-focus="false"
                                             @select="handleSelect" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24">
                        <baidu-map class="bm-view"
                                   :center="mapCenter"
                                   :zoom="mapZoom"
                                   :scroll-wheel-zoom="true"
                                   ak="baidu-ak"
                                   @ready="handlerBMap" />

                    </el-col>
                </el-row>

                <el-row :gutter="10">
                    <el-col :xs="24" :sm="12" :md="12" :lg="12">
                        <el-form-item label="经度:" prop="latitude">
                            <el-input  v-model.number="mapLocation.coordinate.lng" :disabled="true" class="lineinput"  size="mini"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col ::xs="24" :sm="12" :md="12" :lg="12">
                        <el-form-item label="纬度:" prop="lat">
                            <el-input v-model.number="mapLocation.coordinate.lat" :disabled="true" class="lineinput"  size="mini"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24">
                        <el-form-item label="位置:">
                            <el-input class="lineinput"
                                      size="mini"
                                      v-model="mapLocation.address"
                                      :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="warning" size="small" @click="close">取消</el-button>
            <el-button type="primary" size="small" @click="save">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { BaiduMap } from 'vue-baidu-map'
    export default {
        name: "MapView",
        components: { BaiduMap },
        data() {
            return {
                title: '选择地理位置',
                dialogFormVisible: false,
                keyword: '深圳市罗湖区东门南路3005号寸金大厦',
                mapZoom: 21,
                mapCenter: { lng: 0, lat: 0 },
                mapLocation: {
                    address: '',
                    coordinate: {},
                }
            };
        },
        methods: {
            async openDialog() {
                this.dialogFormVisible = true;
            },
            close() {
                this.$refs['form'].resetFields();
                this.form = this.$options.data().form;
                this.dialogFormVisible = false;
            },
            save() {
                /* 关闭窗口并向父组件返回数据 */
                this.close();
                this.$emit('finish', this.mapLocation);
            },
            handlerBMap({ BMap, map }) {
                this.BMap = BMap;
                this.map = map;
                if (this.mapLocation.coordinate && this.mapLocation.coordinate.lng) {
                    this.mapCenter.lng = this.mapLocation.coordinate.lng;
                    this.mapCenter.lat = this.mapLocation.coordinate.lat;
                } else {
                    this.mapCenter.lng = 114.128304;
                    this.mapCenter.lat = 22.548164;
                    this.mapLocation.coordinate = this.mapCenter;
                    this.mapLocation.address = this.keyword;
                }

                map.addOverlay(new this.BMap.Marker(this.mapLocation.coordinate));
                map.mapZoom = this.mapZoom;
                map.enableScrollWheelZoom(true);
            },
            querySearch(queryString, cb) {
                var that = this;
                var myGeo = new this.BMap.Geocoder();
                myGeo.getPoint(queryString, function (point) {
                    if (point) {
                       
                        that.mapLocation.coordinate = point;
                        that.makerCenter(point);
                    } else {
                        that.mapLocation.coordinate = {};
                    }
                }, this.locationCity);
                var options = {
                    onSearchComplete: function (results) {
                        if (local.getStatus() === 0) {
                            // 判断状态是否正确
                            var s = []
                            for (var i = 0; i < results.getCurrentNumPois(); i++) {
                                var x = results.getPoi(i)
                                var item = { value: x.address + x.title, point: x.point }
                                s.push(item)
                                cb(s)
                            }
                        } else {
                            cb()
                        }
                    }
                }
                var local = new this.BMap.LocalSearch(this.map, options)
                local.search(queryString)
            },
            handleSelect(item) {
                var { point, value } = item;
                this.mapLocation.coordinate = point;
                this.mapLocation.address = value;
                this.makerCenter(point);
            },
            makerCenter(point) {
                if (this.map) {
                    this.map.clearOverlays()
                    this.map.addOverlay(new this.BMap.Marker(point))
                    this.mapCenter.lng = point.lng
                    this.mapCenter.lat = point.lat
                }
            }
        }
    };
</script>


<style lang="scss" scoped>
    .bm-view {
        width: 100%;
        height: 300px;
    }
</style>